import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './authService';

@Injectable({
  providedIn: 'root',
})
export class SignInAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    const token = this.authService.getToken();
    if (token) {
      this.router.navigate(['dashboard']);
      return of(false);
    } else {
      return of(true);
    }
  }
}
