import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/authService';
import { ToastrcustomService } from '../../services/toasterService';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  showPassword = true;
  isSubmitted = false;
  isLoading = false;
  portalName: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrcustomService
  ) {
    this.form = this.formBuilder.group({
      Email: [''],
      Password: [''],
    });
  }
  ngOnInit(): void {
    this.getportal();

    this.form = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
    });
  }

  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}

  get errorControl() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.status == 'VALID') {
      this.isLoading = true;

      const email = this.form.controls['Email'].value;
      const password = this.form.controls['Password'].value;

      const requestBody = {
        email: email,
        password: password,
      };

      this.authService.login(requestBody).subscribe(
        (response) => {
          console.log('Login : ', response);
          if (response.data.status === '200') {
            localStorage.clear();
            this.authService.saveToken(response.token);
            this.authService.saveUserDetail(response.user);
            this.router.navigate(['dashboard']);
            this.toastrService.showSuccess('Login successfully', 'Success');
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.toastrService.showError(response.data.logs, 'Failed');
          }
        },
        (error) => {
          console.error('Login error:', error);
          this.isLoading = false;
        }
      );
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  getportal(): void {
    this.authService.getportal().subscribe(
      (response) => {
        console.log(response);
        document.documentElement.style.setProperty(
          '--primary-color',
          response.primary_color.toLowerCase()
        );
        document.documentElement.style.setProperty(
          '--secondary-color',
          response.secondary_color.toLowerCase()
        );
        document.documentElement.style.setProperty(
          '--default-logo',
          response.logo
        );
        document.documentElement.style.setProperty(
          '--default-favicon',
          response.favicon
        );
        this.updateFavicon(response.favicon);
        this.updateMetaTags(response.meta_title, response.meta_description);
        this.portalName = response.name;
      },
      (error) => {
        console.log('Get Portal : ', error);
      }
    );
  }

  getPrimaryLogo() {
    const primaryLogo = getComputedStyle(document.documentElement)
      .getPropertyValue('--default-logo')
      .trim();
    return primaryLogo;
  }

  private updateFavicon(faviconUrl: string): void {
    const linkElement = document.querySelector("link[rel='icon']");
    if (linkElement && faviconUrl) {
      linkElement.setAttribute('href', faviconUrl);
    }
  }

  private updateMetaTags(title: string, description: string): void {
    const titleElement = document.querySelector('title');
    if (titleElement && title) {
      titleElement.innerText = title;
    }

    const descriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionElement && description) {
      descriptionElement.setAttribute('content', description);
    }
  }
}
